<template>
<v-col style="padding-bottom:110px;">
  <v-row>
    <v-col cols="12" sm="11" md="9" lg="7" class="px-0 mt-sm-5 mx-auto">
      <UserPage v-if="entity != null" 
                  :entity="entity" 
                  :inDialog="false">
      </UserPage>
    </v-col>
  </v-row>
</v-col>
</template>


<script>
import UserPage from '@/components/entity/UserPage.vue'
import axios from "axios"

export default {
  name: 'home',
  props: [],
  components: {
    UserPage
  },
  data: () => ({
    showDialog: false,
    entity: null,
    commandeEdit: null
  }),
  mounted: async function(){
    this.$store.dispatch('app/incStat', '/user')
  },
  methods: {
    
  },
  watch: {
      
      '$route.params.userId': { immediate: true, 
          async handler () {
            this.entity = null
            axios.post('/map/search', { id: this.$route.params.userId })
                  .then((res) => {
                    if(!res.data.error) {
                      this.entity = res.data.results.users.entities[0]
                      console.log("ok fetching entity", this.entity)
                    }
                    else console.log("error fetching entity", res.data)
                  })
            //console.log("$store.state.auth.isLogin", this.entity.walletDeposit)
           
          }
      },
  }
  
}
</script>
